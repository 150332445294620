import React from "react"
import SolutionsLayout from "../../components/SolutionsLayout"
import Card from "../../components/Card"
import { Grid } from "@material-ui/core"

export default function Security() {
  return (
    <SolutionsLayout>
      <h2 className="heading-2 left">Security</h2>
      <p className="paragraph left">
        <strong className="bold-text">
          Improve cloud infrastructure security and reduce risk
          <br />
          <br />
          As companies move forward with their cloud strategies, an effective
          and integrated security strategy is essential. Cloud computing
          promises incredible benefits in terms of speed, flexibility, and cost
          but security is the number one concern for enterprises considering
          public cloud adoption.
        </strong>
        <br />
        <br />
        With deep expertise in both cloud strategy and security, we offer
        complete and holistic cloud security solutions that address controls,
        technology, and continuous governance to deliver a secure and compliant
        cloud that meets regulatory needs and concerns.
        <br />
        <br />
        When you choose a reputable cloud services provider or cloud security
        platform, you can kiss goodbye to manual security configurations and
        almost constant security updates. These tasks can have a massive drain
        on resources, but when you move them to the cloud, all security
        administration happens in one place and is fully managed on your behalf.
        <br />
        <br />
      </p>
      <h2 className="heading-2 left">Benefits</h2>
      <ul className="list">
        <li>
          <b>Centralized security:</b> Just as cloud computing centralizes
          applications and data, cloud security centralizes protection.
        </li>
        <li>
          <b>Reduced costs:</b> One of the benefits of utilizing cloud storage
          and security is that it eliminates the need to invest in dedicated
          hardware. Not only does this reduce capital expenditure, but it also
          reduces administrative overhead.
        </li>
        <li>
          <b>Reliability:</b> Cloud computing services offer the ultimate in
          dependability. With the right cloud security measures in place, users
          can safely access data and applications within the cloud no matter
          where they are or what device they are using.
        </li>
        <li>
          Increased operational <b>efficiency</b>
        </li>
        <li>Better business alignment</li>
        <li>Improved services integration</li>
        <li>
          <b>Lower risk</b> profile
        </li>
      </ul>
      <h2 className="heading-2 left">What we offer</h2>
      <Grid container alignItems="stretch" className={"w-container"}>
        <Grid item xs={12} lg={6}>
          <Card title="Dramatic Risk Reduction" icon={12}>
            Our security frameworks provide comprehensive protection for your
            business.
            <br />
            <br />
            Our automated assessments and periodic reviews protect you
            everywhere you are present.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Multi-layered protection" icon={18}>
            SAST, DAST, Dependencies, container image scanning, Fuzzing - all
            integrated to your CI pipeline.
            <br />
            <br />
            WAF, DoS shield, Network/Vulnerability scanners, Firewalling,
            IDS/IPS
            <br />
            Automated CIS controls validation.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Actionable plans" icon={16}>
            Actionable plans to address your biggest concerns regarding security
            and control in the cloud.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Demonstrable compliance" icon={15}>
            Regular review of key cloud security actions. Extend your compliance
            reporting to the cloud.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Multiple integrations" icon={2}>
            We integrate with existing vendors to enrich your current security
            controls.
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card title="Automated assessments" icon={1}>
            Periodic reports based on CIS benchmarks and other industry
            standards.
          </Card>
        </Grid>
      </Grid>

      <br />
      <h2 className="heading-2 left">Why partner with us</h2>

      <ul className="list">
        <li>Rely on the knowledge and insights of cloud security experts</li>
        <li>
          Zero in on insights quickly, so you have more time to spend on your
          business
        </li>
        <li>
          Complete security partner that can provide everything from cloud
          security architecture assessments to 24×7 managed detection and
          response
        </li>
      </ul>
    </SolutionsLayout>
  )
}
